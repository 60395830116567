<template>
<div class="container-fluid px-4">
<div class="row">
  <div class="col-9">
    <div class="row align-items-center">
      <div class="col-8">
        <h2 class="h2 e-text-red">Accident / Incident Yearly Report</h2>
      </div>
      <div class="col-4">
        <select name="" id="" class="form-control form-control-sm" v-model="office_id">
          <option :value="null"> SELECT BRANCH OFFICE</option>
          <option :value="office.id" v-for="office in mngmntOfficeKeys ">
            {{office.name ? office.name.toUpperCase() : ''}}
          </option>
        </select>
      </div>
    </div>
    <div class="row mb-2 justify-content-end">
      <div class="col-auto">
        <button class="btn btn-dark btn-sm force-white-all" @click.prevent="generateCsv"><font-awesome-icon icon="download" class="mr-2"></font-awesome-icon>DOWNLOAD CSV</button>
      </div>
    </div>
    <div class="card shadow-sm mb-4">
      <div class="card-body pb-2">
        <template v-if="loading">
          <app-loading></app-loading>
        </template>
        <div v-else>
          <ai-yearly-chart :category-object="airChartCounts" :years="years" :series="chartInfo" :office="office_id" :categories="chartCategories"></ai-yearly-chart>
        </div>
      </div>
    </div>
    <div class="card shadow-sm mb-4">
      <div class="card-body">
        <h5 class="e-text-red-light-1">Accident / Incident Data</h5>
        <ai-yearly-table :data="airChartCounts" :years="years"></ai-yearly-table>
      </div>
    </div>
  </div>
  <div class="col-3">
    <h2 class="h2 e-text-red">Reports</h2>
    <div class="card shadow-sm rounded mb-3">
      <div class="card-body">
        <h5 class="e-text-red-light-1">Accident / Incident</h5>
        <template v-if="loading">
          <app-loading></app-loading>
        </template>
        <table class="table mb-1" v-else>
          <tr v-for="airCount in airReportStatusCounts">
            <th>{{airCount.name}}</th>
            <th>{{airCount.count}}</th>
          </tr>
        </table>
      </div>
    </div>
    <div class="card shadow-sm rounded mb-3">
      <div class="card-body">
        <h5 class="e-text-red-light-1">Inspection</h5>
        <h6 class="e-text-red-light-1">PSC Inspection</h6>
        <table class="table mb-1">
          <tr>
            <th>NEW</th>
            <th>0</th>
          </tr>
          <tr>
            <th>OPEN</th>
            <th>0</th>
          </tr>
          <tr>
            <th>READY TO CLOSE</th>
            <th>0</th>
          </tr>
          <tr>
            <th>CLOSED</th>
            <th>0</th>
          </tr>
          <tr class="border-bottom">
            <th>RECORD ONLY</th>
            <th>0</th>
          </tr>
        </table>
        <h6 class="e-text-red-light-1">Rightship Inspection</h6>
        <table class="table mb-1">
          <tr>
            <th>NEW</th>
            <th>0</th>
          </tr>
          <tr>
            <th>OPEN</th>
            <th>0</th>
          </tr>
          <tr>
            <th>READY TO CLOSE</th>
            <th>0</th>
          </tr>
          <tr>
            <th>CLOSED</th>
            <th>0</th>
          </tr>
          <tr class="border-bottom">
            <th>RECORD ONLY</th>
            <th>0</th>
          </tr>
        </table>
      </div>
    </div>
    <div class="card shadow-sm rounded mb-3">
      <div class="card-body">
        <h5 class="e-text-red-light-1">Audit</h5>
        <table class="table mb-1">
          <tr>
            <th>NEW</th>
            <th>0</th>
          </tr>
          <tr>
            <th>OPEN</th>
            <th>0</th>
          </tr>
          <tr>
            <th>READY TO CLOSE</th>
            <th>0</th>
          </tr>
          <tr>
            <th>CLOSED</th>
            <th>0</th>
          </tr>
          <tr class="border-bottom">
            <th>RECORD ONLY</th>
            <th>0</th>
          </tr>
        </table>
      </div>
    </div>
    <div class="card shadow-sm rounded mb-3">
      <div class="card-body">
        <h5 class="e-text-red-light-1">Master's Review and Improvement</h5>
        <table class="table mb-1">
          <tr>
            <th>NEW</th>
            <th>0</th>
          </tr>
          <tr>
            <th>OPEN</th>
            <th>0</th>
          </tr>
          <tr>
            <th>READY TO CLOSE</th>
            <th>0</th>
          </tr>
          <tr>
            <th>CLOSED</th>
            <th>0</th>
          </tr>
          <tr class="border-bottom">
            <th>RECORD ONLY</th>
            <th>0</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import AccidentIncidentYearlyChart from "@/components/dashboard/accident-incident/AccidentIncidentYearlyChart.vue";
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading";
import AccidentIncidentYearlyTable from "@/components/dashboard/accident-incident/AccidentIncidentYearlyTable";
import {permissionMixin} from "@/mixins/permissionMixins";
import {ExcelService} from "@/services/ExcelService";
export default {
  name: 'Dashboard',
  mixins:[permissionMixin],
  data(){
    return {
      loading:false,
      startYear:new Date().toISOString().split("T")[0].split('-')[0] - 5,
      currentYear:new Date().toISOString().split("T")[0].split('-')[0],
      office_id: null
    }
  },
  components: {
    'ai-yearly-chart': AccidentIncidentYearlyChart,
    'ai-yearly-table': AccidentIncidentYearlyTable,
    'app-loading' : AppLoading

  },
  methods:{
    ...mapActions(['getVesselTypeKeys','getAirReportStatusCounts','getAccidentCharts','getMngmtOfficeKeys']),
    getChartColor(index){
      const colors=['#bfbfbf','#a6a6a6','#ffd966','#f7caac','#f4b083','#ff0000']
      return colors[index];
    },
    async generateCsv(){
      swal.fire({
        'title': 'GENERATING CSV FILE',
        allowEscapeKey:false,
        allowEnterKey:false,
        allowOutsideClick:false,
      })
      swal.showLoading()
      await ExcelService.csv('ACCIDENT INCIDENT')
      swal.close();


    }
  },
  async created() {
    this.loading=true;
    this.office_id=this.isElite() ? null : this.user.management_office_id
    this.getMngmtOfficeKeys({dashboard:true});
    await this.getAirReportStatusCounts({office_id:this.office_id});
    await this.getAccidentCharts({office_id:this.office_id});
    this.loading=false;
  },
  computed:{
    ...mapGetters(['airReportStatusCounts','airChartCounts','mngmntOfficeKeys','user']),
    years(){
      let years=[];
      for (var i = this.startYear; i <= this.currentYear; i++) {
        years.push(i);
      }
      return years;
    },
    chartInfo(){
      let data=[];
      let stats=[];
      for (var i = this.startYear; i <= this.currentYear; i++) {
        const year={
          name:i,
          color: this.getChartColor(this.years.indexOf(i)),
        }
        this.airChartCounts.forEach(chart=>{
          data.push(chart.years[i])
        });
        year.data=data
        data=[];
        stats.push(year);
      }
      return stats;
    },
    chartCategories(){
      const categories=[];
      this.airChartCounts.forEach(item=>{
        categories.push(item.event)
      });
      return categories;
    }
  },
  watch:{
    async "office_id"(id){
      await this.getAccidentCharts({office_id:id})
      await this.getAirReportStatusCounts({office_id:id})
    }
  }
}
</script>

<style scoped>

</style>

